import React from 'react'
import AboutBlock from '../../components/AboutBlock/AboutBlock'
import MainBlock from '../../components/MainBlock/MainBlock'
import './landing.scss'
import CostBlock from '../../components/CostBlock/CostBlock'
import AccessibilityBlock from '../../components/AccessibilityBlock/AccessibilityBlock'
import FlexibilityBlock from '../../components/FlexibilityBlock/FlexibilityBlock'
import Footer from '../../components/Footer/Footer'

const Landing = () => {
  return (
    <div className="landing_wrapper">
      <MainBlock />
      <AboutBlock />
      <CostBlock />
      <AccessibilityBlock />
      <FlexibilityBlock />
      <Footer />
    </div>
  )
}

export default Landing
