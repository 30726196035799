import React from 'react'
import './FlexibilityBlock.scss'
import bg from '../../img/landing/bgFlex.svg'
import mobileBg from '../../img/landing/flexabilityMobileBg.svg'

const FlexibilityBlock = () => {
  const isMobile = window.innerWidth <= 430

  return (
    <div className="flexibility_bg_wrapper">
      <div className="flexibility_wrapper">
        <img className="flexibility_bg" src={isMobile ? mobileBg : bg} alt="" />

        <div className="flexibility_hr"></div>
        <div className="flexibility_title">Ultimate Flexibility</div>
        <div className="flexibility_sub_text">
          Orion Bridge prides itself on the flexibility of its solutions. Our
          bridges can be customized and integrated with a vast array of
          blockchain networks and applications, empowering businesses and
          individuals to select the solutions that align perfectly with their
          unique needs and aspirations.
        </div>
      </div>
    </div>
  )
}

export default FlexibilityBlock
