import React from 'react'
import './AboutBlock.scss'
import bg from '../../img/landing/aboutBg.svg'

const AboutBlock = () => {
  return (
    <div className="about_wrapper">
      <img className="about_bg" src={bg} alt="" />
      <div className="about_hr"></div>
      <div className="about_info_wrapper">
        <div className="about_info">
          <div className="about_info_title">SEAMLESS INTEGRATION</div>
          <div className="about_info_sub_text">
            We create links between various blockchain networks for smooth and
            efficient transactions.
          </div>
        </div>
        <div className="about_info">
          <div className="about_info_title">UNPARALLELED SECURITY</div>
          <div className="about_info_sub_text">
            Trust your transactions and data with our cutting-edge cryptographic
            techniques.
          </div>
        </div>
        <div className="about_info">
          <div className="about_info_title">BOUNDLESS SCALABILITY</div>
          <div className="about_info_sub_text">
            Capable of managing high-traffic demands without sacrificing
            performance.
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutBlock
