import React from 'react'
import './App.css'
import Landing from './page/landing/landing'
import starBg from '../src/img/bg.png'

function App() {
  return (
    <div
      className="App"
      style={{
        backgroundImage: `url(${starBg})`,
        backgroundPosition: 'center',
      }}
    >
      <Landing />
    </div>
  )
}

export default App
