import React from 'react'
import './CostBlock.scss'
import bg from '../../img/landing/backgroundCost.svg'

const CostBlock = () => {
  return (
    <div className="cost_bg_wrapper">
      <div className="cost_wrapper">
        <img className="cost_bg" src={bg} alt="" />
        <div className="cost_hr"></div>
        <div className="cost_title">Cost Efficiency at its Best</div>
        <div className="cost_sub_text">
          {'Orion Bridge stands for high efficiency and cost-effectiveness.\n'}
          We have designed our bridges to eliminate the need for costly
          intermediaries and excessive transaction fees, democratizing the
          decentralized economy for businesses and individuals alike.
        </div>
      </div>
    </div>
  )
}

export default CostBlock
