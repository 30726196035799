import React from 'react'
import './Footer.scss'
import logo from '../../img/logo.svg'
import twitter from '../../img/landing/twitterIcon.svg'
import githubIcon from '../../img/landing/github.svg'

const Footer = () => {
  const isMobile = window.innerWidth <= 730
  return (
    <div className="footer_wrapper">
      <div className="footer">
        <div className="footer_logo_wrapper">
          <img className="footer_logo" src={logo} alt="" />
        </div>
        <div className="footer_nav"></div>
        <div className="footer_hr"></div>
        <div className="footer_bottom_text">
          {isMobile ? (
            <p className="copyright_text">
              © Copyright 2023, <br />
              All Rights Reserved by Orbiter Labs Inc.
            </p>
          ) : (
            <p className="copyright_text">
              © Copyright 2023, All Rights Reserved by Orbiter Labs Inc.
            </p>
          )}
          <div className="link_wrapper">
            <a
              className="twitter_link"
              href="https://github.com/orionbridge"
              target="_blank"
            >
              <img src={githubIcon} alt="" />
              <p>Github</p>
            </a>
            <a
              className="twitter_link"
              href="https://twitter.com/OrionBridge"
              target="_blank"
            >
              <img src={twitter} alt="" />
              <p>Twitter</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
