import React from 'react'
import './AccessibilityBlock.scss'
import bg from '../../img/landing/accessibilityBg.svg'

const AccessibilityBlock = () => {
  return (
    <div className="accessibility_bg_wrapper">
      <div className="accessibility_wrapper">
        <img className="accessibility_bg" src={bg} alt="" />

        <div className="accessibility_hr"></div>
        <div className="accessibility_title">Unfettered Access for All</div>
        <div className="accessibility_sub_text">
          Our decentralized bridge technology is within the reach of anyone with
          internet access, regardless of location or financial standing. We are
          passionate about unlocking new avenues of participation and innovation
          within the blockchain ecosystem.
        </div>
      </div>
    </div>
  )
}

export default AccessibilityBlock
