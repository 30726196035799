import React, { useState } from 'react'
import bg from '../../img/landing/mainBg.svg'
import mobileBg from '../../img/landing/backgroundMainMobile.svg'
import Header from '../Header/Header'
import './MainBlock.scss'
import ComingSoonModal from '../CommingSoonModal/ComingSoonModal'

const MainBlock = () => {
  const isMobile = window.innerWidth <= 430
  const [open, setOpen] = useState(false)
  const isDev = process.env.REACT_APP_X_API_FOR === 'dev'

  return (
    <div
      style={
        window.innerWidth > 1920
          ? {
              backgroundImage: `url(${isMobile ? mobileBg : bg})`,
              width: '100%',
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }
          : {
              backgroundImage: `url(${isMobile ? mobileBg : bg})`,
              width: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }
      }
    >
      <div>
        <Header setOpen={setOpen} />
        <div className="main_wrapper">
          <p className="title">
            Your Gateway to a Unified, Decentralized World
          </p>
          <p className="sub_text">
            Imagine the future where blockchain boundaries are blurred. Welcome
            to Orion Bridge, where we pioneer in providing decentralized bridge
            solutions that reshape how disparate blockchain networks interact
          </p>
          <div className="button_wrapper">
            <a
              href={
                isDev
                  ? 'https://demo-app.orionbridge.xyz'
                  : 'https://app.orionbridge.xyz'
              }
              target="_blank"
            >
              <button className="button">Launch App</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainBlock
