import React from 'react'
import './Header.scss'
import logo from '../../img/landing/logo.svg'
import logoMobile from '../../img/landing/logoMobile.svg'

interface Props {
  setOpen: (a: boolean) => void
}
const Header: React.FC<Props> = ({ setOpen }) => {
  const isDev = process.env.REACT_APP_X_API_FOR === 'dev'
  const isTablet = window.innerWidth < 1140
  return (
    <div className="wrapper">
      <div className="logo_wrapper">
        <img src={isTablet ? logoMobile : logo} alt="" />
      </div>
      <div className="button_wrapper">
        <a
          href={
            isDev
              ? 'https://demo-app.orionbridge.xyz'
              : 'https://app.orionbridge.xyz'
          }
          target="_blank"
        >
          <button className="button">Launch App</button>
        </a>
      </div>
    </div>
  )
}

export default Header
